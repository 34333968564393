<template>
	<div class="app-box">
		<div class="top-box" :gutter="10">
			<div class="card" v-for="(item, index) in cardList" :key="index">
				<div class="left">
					<div class="txt-label">{{ item.name }}</div>
					<div class="txt-num">{{ item.num }}</div>
				</div>
				<img src="@/assets/item.png" class="img-right">
			</div>
		</div>
		<div class="grey-line"></div>
		<div class="bottom-box">
			<div class="row-title">
				<div class="title">预约服务</div>
				<div class="txt-see" @click="toRe()">查看全部</div>
			</div>
			<div class="ul-list">
				<div class="li" v-for="item in resereList" :key="item.reservation_id">
					<img src="@/assets/head_img.png" class="img-left">
					<div class="txt-box">
						<div class="txt-up">
							<div class="txt-black">{{ item.tag }}</div>
							<div class="txt-black">{{ levelMap[item.level_id] }}级会员</div>
							<div class="txt-grey">{{ item.name }}</div>
							<div class="txt-grey">提交了</div>
							<div class="txt-blue" @click="toRe()">{{ item.service_name }}</div>
							<div class="txt-grey">预约</div>
						</div>
						<div class="txt-date">{{ item.create_at_str }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		return {
			cardList: [
				{
					name: '用户数（位）',
					num: 0
				},
				{
					name: '服务统计（条）',
					num: 0
				},
				{
					name: '待处理服务（条）',
					num: 0
				},
				{
					name: '总营业额（元）',
					num: 0
				}
			],
			resereList: [],
			levelMap: {}
		}
	},
	created() {
		this.$post(domain + '/admin/v1/reservation/overview', {}).then(res => {
			if (res.code === 0) {
				const cardList = this.cardList
				const data = res.data
				cardList[0].num = data.peopleNumber
				cardList[1].num = data.serviceNumber
				cardList[2].num = data.onHandServiceNumber
				cardList[3].num = data.allMoney
			} else {
				this.$message.error(res.msg)
			}
		})
		this.$post(domain + '/admin/v1/reservation/list', { page: 1, limit: 5, type: "online" }).then(res => {
			if (res.code === 0) {
				this.resereList = res.data.list.slice(0, 5)
			} else {
				this.$message.error(res.msg)
			}
		})
		this.$post(domain + '/admin/v1/customerLevel/listNoPage').then(res => {
			if (res.code === 0) {
				const levelMap = {}
				res.data.map(item => {
					levelMap[item.level_id] = item.name
				})
				this.levelMap = levelMap
			} else {
				this.$message.error(res.msg)
			}
		})
	},
	methods: {
		toRe() {
			this.$router.push({
				path: '/reservationList',
			})
		},
	}
}
</script>

<style lang="less" scoped>
@w: 7.2;
@h: 4;

.app-box {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
}

.top-box {
	display: flex;
	padding-top: 7vh/@h;
	padding-bottom: 7vh/@h;
	height: 78vh/@h;

	.card {
		height: 100%;
		background: #F5F6FD;
		border-radius: 1px;
		margin-left: 10vw/@w;
		flex-grow: 1;
		padding: 12vh/@h 10vw/@w;
		display: flex;
		justify-content: space-between;

		&:last-child {
			margin-right: 10vw/@w;
		}
	}

	.left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.txt-label {
		font-size: 8vh/@h;
	}

	.txt-num {
		font-size: 18vh/@h;
	}

	.img-right {
		height: 40vh/@h;
	}
}

.grey-line {
	height: 12vh/@h;
	background-color: #eaedf0;
}

.bottom-box {
	flex-grow: 1;
}

.row-title {
	display: flex;
	height: 28vh/@h;
	align-items: center;
	border: 1px solid #E8E8E8;
	justify-content: space-between;

	.title {
		margin-left: 10vw/@w;
		font-size: 8vh/@h;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.85);
	}

	.txt-see {
		margin-right: 20vw/@w;
		font-size: 7vh/@h;
		color: #1890FF;
		cursor: pointer;
	}
}

.ul-list {
	padding-left: 10vw/@w;
	padding-right: 20vw/@w;
	padding-top: 10vh/@h;

	.li {
		height: 40vh/@h;
		border-bottom: 1px solid #E8E8E8;
		display: flex;

		.img-left {
			width: 16vh/@h;
			height: 16vh/@h;
			margin-top: 12vh/@h;
			border-radius: 50%;
			background-color: #F0F2F5;
		}

		.txt-box {
			margin-top: 12vh/@h;
			margin-left: 7vw/@w;
			font-size: 7vh/@h;
		}

		.txt-up {
			display: flex;
		}

		.txt-black {
			font-weight: bold;
			color: rgba(0, 0, 0, 0.65);
			margin-right: 2vw/@w;
		}

		.txt-grey {
			color: rgba(0, 0, 0, 0.65);
			margin-right: 2vw/@w;
		}

		.txt-blue {
			color: #1890FF;
			margin-right: 2vw/@w;
		}

		.txt-date {
			margin-top: 2vh/@h;
			color: rgba(0, 0, 0, 0.45);
		}
	}
}
</style>
